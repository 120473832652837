/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";
@media all and (max-width: 767px) {  

    .wrapper {width: auto; margin: 0 20px;}      

    span.tgl {
        &.wht {color: #999;}
    }

    header {
        .bot {
            .left_header {
                .logobot {width: 200px;}   
            }
        }
    }

    .menumobile {
        .navacc {
            ul {
                li {
                    a {
                        font-size: 14px; 
                        &.parent {}
                    }
                    ul {
                        li {
                            a {font-size: 13px;}     
                        }
                    }
                }
            }
        }
        .footmob {
            .row {margin-top: 25px;} 
        }
    } 

    .btn {		
        &.slide {
            height: 40px; line-height: 40px; padding: 0 25px; font-size: 12px; border-width: 1px;   
            @include borderRadius(4px);   
        }  
        &.start {
            margin: 20px 0 20px 0;
            font-size: 14px;
        }
    }

    .box-share-right { 
        width: 100%; top: 100%; margin-top: -54px; text-align: center;  
        a {display: inline-block; margin-right: -3px;}     
    }

    .banner-slider {    
        .list-slider { 
            .wrapper {width: 90%; left: 50%; margin: 0 auto; @include transform(translateX(-50%));} 
            .box-caption {
                width: auto; top:50%; left: 0; padding-left: 0; height: 100px;
                @include transform(translate(0, -50%)); 
                .tittle-capt {
                    padding: 0 20px; @include boxSizing(border-box);
                    i {font-size: 14px;}
                    h1 {font-size: 30px;}
                }
                p {display: none;} 
            }  
            .image-slide { 
                height: 92vh; overflow: hidden;
                img {display: block; width: 100%;height: 100%; object-fit: cover;}  
            }     
        }
        .slick-dots {bottom: 20px; padding:0 15px;}    
    }

    .banner-std {
        img {height: 225px;}
    }

    .home {
        .tittle { 
            h1 {
                padding:60px 0 25px 0; font-size: 26px;
                &.num {padding-bottom: 20px;} 
            }
        }
        i.tittle {font-size: 13px; margin-bottom: 10px;}
        &.visimisi {
            .tittle-sec {
                margin-top: 50px; padding-bottom: 15px; 
                h2 {font-size: 24px;}  
            } 
            .bg-visi {top: 18%; width: 100%;} 
            .box-visimisi {
                padding-left: 0; padding-top: 300px; left: 0;
                .images-visi {top: 0; height: 260px;}
                h3 {font-size: 28px; line-height: 30px;} 
                .visi {margin-bottom: 25px;} 
                p {margin-bottom: 30px;} 
            }   
        }
        &.news-event { 
            padding-bottom: 30px; 
            h1 {font-size: 20px;} 
            .tittle-news {
                padding-top: 40px; height: auto;
                &:after {display:block; clear: both; content: "";}
                .seeall {
                    margin-top: 25px; 
                    a {
                        &.detail {
                            text-indent: -999999px; padding: 0;
                            &:before {top:0;} 
                        }
                    }  
                }
            } 
            .box-list {		
                width: 100%; margin: 0 0 0 0; position: relative; 		
                .box {
                    width: 100%; position: relative; height: auto; padding: 0; 	 				
                    .sub-box {
                        height: auto; margin-right: 0; width: 100%; padding: 0px; background: none;
                        border-bottom: 1px solid #ccc; margin-bottom: 16px; padding-bottom: 16px;
                        &:first-child {position: relative; top: 0%; margin-top: 0; right: 0px; margin-bottom: 16px;}  
                        a {
                            position: relative; 
                            h4 {font-size: 15px; line-height: 20px; margin: 10px 0 20px 0; color: #333;}       
                            i.round {left: 15px; font-size: 11px;}
                            span.ico {right: 5px; top: 5px; width: 24px; height: 18px; background-size:24px auto;} 
                            .loc {border:0;} 
                        }
                        .isi-sub {
                            span.tgl {position: absolute; right: 0; top: 0; color: #999; font-size: 11px;}   
                        }
                    }					 
                    &.big {
                        width: 100%; height: auto;
                        overflow: hidden; position: relative;         
                        figure {
                            a {
                                position: relative; width: 100%; display: block;
                                &.video { 
                                    &:after {
                                        margin:0; width: 32px; height: 32px; background-size: 100%;
                                        left: 30px; top: 25px; 
                                    } 
                                }
                            }
                            img {width: 90px; height: 90px; object-fit: cover; top: 0; position: absolute; left: 0;} 
                            .caption {
                                left:0; bottom: 0; width: 100%; padding:0 0 15px 100px; @include boxSizing(border-box);
                                color: #333; position: relative;  
                                h5 {font-size: 15px; line-height: 18px;}     
                            }
                            i.round {
                                left: 100%; font-size: 11px;
                                &.wht {color: #999; position: absolute; top: 12px; margin-left: -30px;} 
                            }
                            span.ico {display: none;} 
                        }  
                    }
                }
                &.left {
                    .box {
                        margin-top: 16px;
                        &.big {margin-top: 0;}
                    }
                }
                &.right { 
                    .box {
                        margin-top: 0px;
                        .sub-box {
                            &:first-child {}
                        }
                        &.big {margin-top: 0; position: relative; bottom: 0; right: 0px;}

                    }
                }
            } 
        }

        &.virtual {
            height: 500px;
            overflow: hidden;
            .img-full {
                height: 100%;

                img {} 					 
            }
            .caption-virtual {
                width: auto; margin: 0 20px;
                img {width: 43px;} 
                h1 {font-size: 22px; font-weight: 600;} 
                p {display: none;} 
                a {margin-top: 0px;}  
            } 
        }

        &.family {
            padding-bottom: 55px; 
            .box-img {
                .list-img {
                    width: 100%; margin: 0 0 1px 0; 
                    img {width: 100%;}  
                    .label {left: 10px; bottom: 20px; font-size: 16px; line-height: 20px;}  
                } 
            }
        }

        &.number {
            .box-top {
                margin: 0; padding-top: 40px;
                .tittle {
                    text-align: left; 
                    h1 {font-size: 22px;} 
                }
                .box-info {
                    margin-bottom: 45px;  
                    .list-info {
                        width: 100%; height: 55px; border-bottom: 1px solid #3a4097; 
                        .isi {
                            img {left: 0;}  
                            i.info {top:5px; left: 45px;} 
                            h2 {top: 0;}
                        }
                    }  
                }
            }
            .box-bot {
                .tittle-info {
                    h1 {font-size: 22px; font-weight: 500;} 
                }
            }
        }
        &.family-slider{
            padding: 50px 0px;
            .title-sec{
                h2{font-size: 32px;}
            }
        }
        &.instgram {
            .tittle-sec{
                h2{
                    font-size: 22px;
                }
            }
            .feed{
                flex-flow: row wrap;
                a{
                    flex: 0 0 50%;
                    max-width: 50%;
                    &:nth-child(even){
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .map {
            padding-bottom: 100px;  
            #idMap {height: 250px;} 
            .txt-map {
                left: 50%; top: 40%; width: 260px; height: 180px; min-height: 180px; padding: 20px; 
                @include transform(translateX(-50%));  
            } 
        }
        &.what-other{
            .title-sec{
                h2{
                    font-size: 28px;
                    margin-bottom: 20px;
                }
            }
            .slider{
                .slick-arrow{
                    width: 30px;
                    height: 30px;
                }
                figure{
                    figcaption{
                        opacity: 1;
                        h4{
                            
                        }
                    }
                }
            }
        }
        &.family-slider{
            .slider{
                .slick-arrow{
                    width: 30px;
                    height: 30px;
                    &.slick-prev{
                        left: -15px;
                    }
                    &.slick-next{
                        right: -15px;
                    }
                }
            }
        }
    }
    .banner-top{
        overflow: visible;
        max-height: 100%;
        height: auto;
        .wrap-box{
            margin: 0;
            height: 100%;
            h2{
                font-size: 22px;
                &.fonts-oldenglish{
                    font-size: 40px;
                    line-height: 1;
                }
            }
            .box{
                flex:0 0 100%;
                max-width: 100%;
                height: 100%;
                padding: 0;
                margin-bottom: 10px;
                max-height: 100%;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
            }
        }
    }
    .top-menu {
        .left {
            width: 75%;
            &.breadcrumb {
                ul {
                    li {
                        display: none; margin-right: 5px; font-size: 11px; 
                        &:first-child {display: inline-block;}
                        &:last-child {display: inline-block; width: 40%; height: 45px; overflow: hidden;}
                    }
                }
            }
        }
        .right {
            &.submenu {
                span {display: none;}   
            }
        }
    }
    .box-drop-dots-menu {width: 280px;}  

    .content-std {
        h1 {
            font-size: 20px; margin-bottom: 20px;
            &:after {width: 50px;} 
            &.left {width: 100%;}
        }
        h2 {font-size: 17px; line-height: 22px; margin-bottom: 20px;}    
        h3 {
            line-height: 26px; 
            &.bl-f1 {font-size: 18px;}  
            &.itl {padding-top: 30px;} 
        }
        h5 {
            &.reg {line-height: 20px;}   
        }
        h6 {
            &.bld {font-size: 13px;}
            &.std {font-size: 13px;} 
        }

        p {
            &.list {padding-left: 35px;} 
            &.pad {padding-left: 15px;}  
        }

        .inner-content {    
            padding: 25px 18px;     
            ol {
                li {margin-left: 10px;}
            }
            ul {
                li {
                    margin-left: 10px; 
                    &.num {padding-left: 5px;}   
                }
            }
            .infor-collage { 
                margin: 10px 0 30px 0;
                &.requir {padding: 20px;} 
            }
            .li-font {font-size: 16px; padding-left: 20px;} 
            .logo-partn {margin: 30px 0 20px 0;}

            &.filt {
                padding:20px 18px;
                .wrap-select {
                    width: 100%; margin:20px 0 20px 0;
                    select {width: 100%;}  
                } 
            }

            .wrap-list {
                .list-organi {
                    .org {
                        .org-left {padding-right: 6px;}
                        .org-right {padding-left: 6px;}
                    }
                }
            }

            .contact-infor {    
                .list-contact { 
                    width: 100%; margin: 0 0 15px 0; padding: 15px;
                    .bot-con {
                        h6 {word-wrap: break-word; font-size: 11px;}   
                    }
                }   
            }

            .content-src {
                .box-search {
                    .list-search {padding-bottom: 20px; margin-bottom: 20px;}    
                }
                .search-text {
                    input[type="text"] {font-size: 14px; background-position: right center;} 
                    small {font-size: 12px;}
                }
            }
            .filosofi{
                .logo-big{
                    float: none; display: block; width: 100%;
                    .wrap-span{
                        float: none; margin:0 auto; width: 200px; margin-bottom: 20px;
                        > span{
                            &.left{ left: 30px; bottom: 60px;  }
                            &.top { right: 30px; top: 10px; }
                            &.right{ right: 10px; bottom: 15px;  }
                            &.active{
                                width: 40px; height: 40px;
                                .small{ width: 10px; height: 10px; }
                            }
                            .small{ width: 7px; height:7px;	}
                            .medium{ width: 20px; height: 20px;  }
                        }
                    }
                }
                .accordion{ 
                    float: none; display: block; width: 100%;
                }
            }
            .wrap-table{
                table{ 
                    width: 100%;
                    /*tr{
                        td{ display: block; width: 100%; }
                    }*/
                    thead{ display: none; }
                    tbody{
                        tr{
                            td{
                                position: relative; display: block; width: 100%; text-align: left; padding-left: 110px; font-size: 14px;
                                &:nth-child(1n+1){ 
                                    &:before{ content:attr(data-title) ""; position: absolute;  width: 80px; left: 15px; top:50%; height: auto; overflow: hidden; font-weight: 700; @include transform(translateY(-50%));  }
                                }
                            }
                        }
                    }
                }
             }
        } 

        .slide-overview {
            margin:20px 0 30px 0; 
            .slider-img {
                padding-bottom: 0px;
                .slick-arrow{ width: 30px; height: 30px; }
            }
            .slider-text {
                width: 220px; height: 210px; right: 0; left: 50%; top: 35%;
                @include transform(translateX(-50%)); 
                .text {
                    padding: 20px 20px 55px 20px;  
                }
            }
        }  

        .sub-content {
            .left-absolute {left: 50%; @include transform(translateX(-50%));}    
        }

        .paragraph { 
            margin-bottom: 30px; 
            h5 {font-size: 16px; line-height: 20px;}  
        } 

        .wrap-box-admission { 
            .list-box {
                width: 100%; margin: 0 0 20px 0; 
                .box {
                    h4 {font-size: 16px;}
                    h5 {margin-bottom: 5px;} 
                } 
            }  
        }
        .flow {margin: 20px 0 30px 0;}  
        .wrap-box-testimoni {
            .list-testimoni {
                width: 100%; margin: 0 0 20px 0;
                .box-testi {
                    padding: 25px 20px;	
                    .text {
                        h4 {font-size: 18px;} 
                        p {font-size: 14px; line-height: 22px;}  
                    }
                    .bot-testi {margin: 0 -20px -30px -20px; padding: 5px 20px;}   				
                } 				
            }
        }

        .bot-img {
            padding: 0 20px 30px 20px; margin: 0; 
            .lis-bot-img {
                width: 100%; margin-right: 0; padding: 0; margin:0 0 20px 0;  
                a {display: block;} 
                img {width: 100%;}
            }
        }

        .form {   
            .row {
                label { 
                    width: 100%; padding-right: 0; font-size: 16px;   
                    &:before {display:none;} 
                }
                .field { 
                    margin: 0;
                    .opt {font-size: 16px;} 
                    .phone-num {top: 47px;} 
                } 
            }
            hr {width: 100%;}
            .form-chkbox {
                .chkbox {font-size: 16px;}   
            }
        }

        .list-issue {
            margin: 0 25px; 
            .box-issue {
                width: 100%; margin:0 0 30px 0; 
            }
        }

        .box-wrap-news {
            padding: 0 20px; 
            .list-news {
                position: relative; padding-bottom: 18px; margin-bottom: 18px; min-height: 90px;
                .foto {
                    width: 90px; height: 90px; position: absolute; top: 0; left: 0; overflow: hidden;
                    img {
                        height: 100%; width: auto; max-width: none; position: absolute; left: 50%;
                        @include transform(translateX(-50%));  
                    }
                }
                .text-news {
                    width: 100%; padding:0 0 0 100px; position: relative;   
                    @include boxSizing(border-box); 
                    h3 {font-size: 13px; line-height: 18px; margin-bottom: 0;} 
                    h6 {position: absolute; top: 0; right: 0; font-size: 11px;} 
                }
            }
        }

        .wrap-newsdetail {
            padding: 20px;
            .box-newsdetail {
                .list-newsdetail {width: 100%; margin: 0 0 20px 0;} 
            }
        }


    }
    .sub-content {
        padding-left: 0;
        .left-absolute {
            position: relative; margin: 0 auto 20px auto; float: none;
        } 
        .sign {margin: 30px 0 20px 0;}   
        &.havemrg {
            p {width: 100%; margin-bottom: 25px;}  
        }
        .btn-share {
            position: fixed; left: 0; width: 100%; top: 100%; height: 30px; z-index: 7878;  
            margin-top: -30px;
            span {padding-left: 18px;} 
        }
    }
    
    .list-acc {
        .grid-acc {
            padding-right: 45px; 
            .tittle-acc {font-size: 16px;}  
        } 
        .content-acc {
            padding-top: 10px; margin-bottom: 30px; 
            ul {
                margin-bottom: 20px;
                li {
                    padding-left: 20px;
                    h5 {width: 100%; font-size: 14px;}     
                }
                &.academic-acc {    
                    li {
                        padding: 11px;
                    }
                }
            }
            .isi-txt {padding: 0;} 
            .left{
                width: 40%;
            }
            .right{
                span{ display: none; }
                p{ 
                    padding-right: 20px; text-decoration: underline; color: #1b2287;
                    &:before{ display:none; }
                    &:after{ display:none; }
                }
            }
        }
    }


    .map {
        &.contactus {
            padding: 20px 20px 200px 20px;
            #idMap {height: 350px;} 
            .txt-map {
                top:60%; left: 50%; width: 210px; padding: 20px 15px;
                @include transform(translateX(-50%)); 
            }
        }
    }


    .calendar-event .ui-widget.ui-widget-content { 
        padding: 20px 0; 
    }
    .calendar-event .ui-datepicker .ui-widget-header {margin-bottom: 20px;}   
    .calendar-event .ui-datepicker .ui-datepicker-title {font-size: 16px; height: 25px; line-height: 25px;}
    .calendar-event .ui-datepicker .ui-datepicker-prev span {font-size: 10px; padding-left: 15px; text-indent: -99999px;}
    .calendar-event .ui-datepicker .ui-datepicker-next span {font-size: 10px; padding-right: 15px; text-indent: 99999px;}
    .calendar-event .ui-datepicker table td {height: 35px; padding: 10px;}
    .calendar-event .ui-state-default, .calendar-event .ui-widget-content .ui-state-default, .calendar-event .ui-widget-header .ui-state-default, .calendar-event .ui-button, .calendar-event .ui-button.ui-state-disabled:hover, .calendar-event .ui-button.ui-state-disabled:active {
        font-size: 10px; 
    }

    .wrap_popup {
        .content_pop {width: 65%; margin: 35% auto; height: auto; min-height: 200px;}   
    }


    footer { 
        padding-bottom: 54px;  
        .top-footer {
            padding: 30px 0; 
            .top-info {
                .logo {margin: auto; float: none; margin: 0 auto 20px auto;}    
                .addrs {display: none;} 
                .ico-sosmed {float: none; margin: auto; text-align: center; padding: 0;}  
            }
        }
        .bot-footer {
            .left {width: 100%; margin: 0 0 10px 0;}    
            .right {width: 100%;} 
            .ic-partner {float: none; text-align: center;}
            .copy {
                text-align: center; font-size: 10px;
                p {line-height: 12px;}
            } 
            .desc {
                float: none; 
                .nav-bot {
                    width: 200px; margin: 0 auto;
                    padding: 0 0 10px 0; text-align: center;  
                    .item {
                        font-size: 10px; margin-left: 2px; float: none; display: inline-block;
                        &:nth-child(3n+3) {border-left: 0; padding-left: 0; margin-left: 0;}
                    } 
                }   
            } 
        }
    }

    .content-std .inner-content .box-circ{
        margin-right: 0;
        .list-circ{
            float: none; display: block; width: 100%; margin-bottom: 20px;
            .circle{ 
                width: 200px; height: 40px; @include borderRadius(30px); margin: 0 auto;
                h6{ font-size: 12px; }
                &:before{
                    width: 3px; height: 100%; left: 0; right: 0; margin: auto; top: 100%; background: #1b2287;
                }
                &:after{ display: none; }
            }
        }
    }

   /* table{ 
        width: 100%;
        td{ display: block; }
     }*/
     

    #calendar{
        table{margin:0;}
        td{
            width: 14.28%;padding:0;@include boxSizing(border-box);
           &.fc-day-top {padding: 0;}
           &.fc-event-container{padding-top: 5px;}
        }
        .fc-toolbar{
            &.fc-header-toolbar{
                .fc-left{ float: none; display: block; padding-bottom: 30px; }
                .fc-right{ float: none; display: block; 
                    &:after{ content: ''; display: block; clear: both; }
                }
                .fc-center{ float: none; display: block; }
                &:after{ content: ''; display: block; clear: both; }

            }
        }
    }
    
}

@media (max-width: 540px) {  
.content-std .inner-content{
        .wrap-table{
            table{ 
                thead{ display: none; }
                tbody{
                    tr{
                        td{
                            padding: 10px 15px;
                            &:nth-child(1n+1){ 
                                &:before{ content:attr(data-title) ""; position: relative;  width: 100%; left: auto; top: auto;  @include transform(translateY(0)); display: block; margin-bottom: 10px; }
                            }
                        }
                    }
                }
            }
         }
    }
}